import React, { ReactElement, useMemo } from 'react';

import styles from './BreadCrumbs.module.scss';
import Link from '@components/Link';
import Skeleton from '@ingka/skeleton';
import { space100, space550 } from '@ingka/variables';
import Text from '@skapa/Text';
import useTranslations from 'context/Translations';
import { useRouter } from 'next/router';

import useGetList from 'apis/list/hooks/useGetList';

const ListNameCrumb = ({ listId }: { listId: string }): ReactElement => {
    const { data, isLoading } = useGetList(listId);
    if (isLoading) {
        return <Skeleton height={space100} width={space550} />;
    }
    return (
        <Text bodySize="s" tagName="p" className={styles.listName}>
            {data?.list.name}
        </Text>
    );
};

const preparePath = (path: string) => {
    // Matches country, language prefix, square brackets and trailing slash.
    const pattern = /\/\[?\w{2}\]?\/\[?\w{2}\]?\/|\/$|[[\]]/g;
    return path.replace(pattern, '').split('/');
};

type Props = {
    pageDepth?: number;
};

const BreadCrumbs: React.FC<Props> = ({ pageDepth = 2 }) => {
    const translate = useTranslations();
    const { pathname, asPath } = useRouter();

    const crumbs = useMemo(() => {
        const pathRenderers: Record<
            string,
            (pathVariable: string) => string | ReactElement
        > = {
            favourites: () => translate('LISTOVERVIEW_NAVIGATION_LISTS'),
            listId: pathVariable => <ListNameCrumb listId={pathVariable} />,
            'receive-share': () => translate('LISTDETAIL_TITLE_ANONYMOUS'),
        };
        const browserPath = preparePath(asPath);
        return preparePath(pathname)
            .map((pathVariable, i) => ({
                path: browserPath.slice(1, i + 1).join('/'),
                Crumb:
                    pathRenderers?.[pathVariable]?.(browserPath[i]) ||
                    pathVariable,
            }))
            .slice(0, pageDepth);
    }, [asPath, pageDepth, pathname, translate]);

    if (crumbs.length <= 1) {
        return null;
    }

    return (
        <nav aria-label="Breadcrumb">
            <ol className={styles.breadcrumbs}>
                {crumbs.map(({ Crumb, path }, i) => (
                    <li key={i} className={styles.crumb} aria-current="page">
                        {i + 1 < crumbs.length ? (
                            <Link href={path}>{Crumb}</Link>
                        ) : (
                            Crumb
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default BreadCrumbs;
